<template>
  <div class="l-box">
    <div style="width: 100%; text-align: right; padding-right: 10px">
      <span style="margin-right: 10px; cursor: pointer" @click="returnLogin"
        >返回登录</span
      >
    </div>
    <p class="plarfrom-name">找回密码</p>
    <div v-show="isShow">
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <FormItem prop="phone" label="电话号码">
          <Input
            placeholder="请输入手机号"
            v-model="form.phone"
            clearable
          ></Input>
        </FormItem>
        <FormItem prop="code" label="验证码">
          <Input placeholder="请输入验证码" v-model="form.code">
            <!-- <span
              slot="append"
              style="cursor: pointer"
              @click="sentCode"
              v-if="isSend"
              >获取验证码</span
            >
            <span slot="append" v-if="!isSend">{{ isPointer }}</span> -->
            <span slot="append">
              <Button @click="sentCode" :disabled="isSend">{{
                isPointer
              }}</Button>
            </span>
          </Input>
        </FormItem>
        <FormItem prop="password" label="新密码">
          <Input
            password
            type="password"
            placeholder="请输入密码"
            v-model="form.password"
          ></Input>
        </FormItem>
        <FormItem prop="surePassword" label="确认新密码">
          <Input
            password
            type="password"
            placeholder="请确认密码"
            v-model="form.surePassword"
          ></Input>
        </FormItem>

        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <Button
            type="primary"
            style="margin-bottom: 20px; width: 260px"
            @click="submit"
          >
            确认修改密码
          </Button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { V2 } from "@/common/http/api";
import axios from "axios";
import CU from "@/common/util/index";
export default {
  name: "",
  components: {},
  data() {
    var SurePassword = (rule, value, callback) => {
      if (value !== "") {
        if (value !== this.form.password) {
          callback(new Error("密码不一致！"));
          return false;
        } else {
          callback();
        }
      }
    };
    return {
      isShow: true,
      form: {
        phone: "",
        code: "",
        password: "",
        surePassword: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号" },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码" },
          {
            validator(rule, value, callback) {
              if (value.length >= 6) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "密码长度不得小于6位",
            trigger: "blur",
          },
        ],
        surePassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { validator: SurePassword, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码" }],
        ensure: [
          { required: true, message: " " },
          {
            validator(rule, value, callback) {
              if (value) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请勾选同意选项！",
            trigger: "blur",
          },
        ],
        companyName: [{ required: true, message: "请填写公司名称" }],
        name: [{ required: true, message: "请填写联系人" }],
        // region:[{required:true,message:"请选择地址区域"}],
        inDetail: [{ required: true, message: "请输入详细地址" }],
      },
      currentIndex: 0,
      isPointer: "获取验证码",
      //可以点击
      isSend: false,
      // 上册获取验证码的手机
      lastGetCodePhone: "",
    };
  },
  methods: {
    returnLogin() {
      this.$emit("returnLogin");
    },
    next() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.isShow = false;
      });
    },
    returnRegis() {
      this.isShow = true;
    },
    //发送验证码
    sentCode() {
      if (!this.form.phone) {
        this.$Message.error("请填写手机号");
        return;
      }
      if (!CU.validatePhone(this.form.phone)) {
        this.$Message.warning("请输入正确的手机号");
        return;
      }
      this.lastGetCodePhone = this.form.phone;
      axios({
        url: V2 + this.$api.REGISTER.CODE,
        method: "post",
        data: {
          phone: this.form.phone,
        },
      });
      if (!this.isSend) {
        this.isSend = true;
        let n = 31;
        let timer = setInterval(() => {
          n--;
          this.isPointer = n + "s之后重新获取";
          if (n == 0) {
            clearInterval(timer);
            this.isSend = false;
            this.isPointer = "获取验证码";
          }
        }, 1000);
      }
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        if (this.form.phone != this.lastGetCodePhone) {
          this.$Message.error("更换手机号请重新获取验证码");
          return;
        }
        axios({
          method: "post",
          url: V2 + this.$api.REGISTER.CHECKS_CODE,
          data: {
            phone: this.form.phone,
            code: this.form.code,
          },
        }).then((res) => {
          if (res.data.code == 200 && res.data.data) {
            axios({
              method: "post",
              url: V2 + this.$api.REGISTER.RESET,
              data: {
                phone: this.form.phone,
                password: this.form.password,
                code: this.form.code,
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.$Message.success("修改成功！请返回登录界面登录！");
                this.returnLogin();
              } else if (res.data.code == 400) {
                this.$Message.error("该用户不存在！");
                return;
              }
            });
          } else {
            this.$Message.error("验证码输入错误！");
          }
        });
      });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="less" scoped>
/deep/ .ivu-input-group-append .ivu-btn,
.ivu-input-group-prepend .ivu-btn {
  box-shadow: none;
}
/deep/ .ivu-btn.disabled,
.ivu-btn[disabled] {
  cursor: not-allowed;
  color: #999;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.l-box {
  width: 400px;
  padding: 10px;
  margin: 0 0 0 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #cde6f8;
  border-radius: 33px;
  .logo {
    margin-top: 20px;
    width: 124px;
    height: 68px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .plarfrom-name {
    font-size: 24px;
    padding: 25px 0;
    font-weight: bold;
    color: #333;
    letter-spacing: 5px;
  }
  .btn {
    width: 260px;
  }
  .register {
    width: 260px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    span {
      font-size: 12px;
      cursor: pointer;
      // color: #d3d3d3;
    }
  }
}
.choose-role {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 60px;
  li {
    display: inline-block;
    width: 65px;
    height: 100%;
    line-height: 48px;
    text-align: center;
    border-radius: 4px;
    background-color: #f4f5f5;
    cursor: pointer;
  }
  .active {
    background-color: rgba(6, 186, 161, 0.2);
    color: #4dcbb9;
    position: relative;
  }
}
/deep/ .ivu-form {
  width: 360px !important;
}
</style>